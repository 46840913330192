import * as React from 'react';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import AnimatedDoubleList from "./animatedList/AnimatedDoubleList";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import CollapseTagCard from "./CollapseTagCard";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AnimatedList from "./animatedList/AnimatedList";
import {
    niceBytes
} from "../Helper/SharedFunctions";
import AddPlacementsSummaryTable from "./AddPlacementsSummaryTable";

function AddPlacementModal(
    {
        open,
        handleClose,
        addNewPlacements,
    }
) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [exampleType, setExampleType] = useState('Url');
    const [exampleUrl, setExampleUrl] = useState('');
    const [exampleUrls, setExampleUrls] = useState([]);
    const [loadingFile, setLoadingFile] = useState(false);
    const [exampleFiles, setExampleFiles] = useState([]);
    const [tags, setTags] = useState({});
    const [mainContent, setMainContent] = useState([]);

    const fileInput = React.createRef();
    useEffect(_ => {
        if (open) {
            setMainContent([]);
            setName('');
            setDescription('');
            setExampleUrl('');
            setTags({});
        }
    }, [open])
    const handleChangeSwitch = (event) => {
        setExampleType(
            !event.target.checked ? "Url" : "file"
        )
    }

    function onExampleFileChange(fileList) {
        if (fileList.length === 0) {
            return;
        }
        setLoadingFile(true);
        const reader = new FileReader();
        const fileListArray = Array.from(fileList);
        console.log(fileListArray);
        fileListArray.forEach( (file) => {
            reader.onloadend = function () {
                const newFile = {
                    base64: reader.result,
                    name: file.name,
                    size: file.size,
                };
                setExampleFiles(prevState => [...prevState, newFile ])
            };
            reader.readAsDataURL(file);
        })
        setLoadingFile(false);
    }

    const handleAddToMainList = () => {
        const placement = {
            name,
            description,
        }
        console.log({exampleUrls})
        if (exampleType === 'Url' &&  exampleUrls.length > 0) {
            placement.exampleUrls = exampleUrls;
        }
        if (exampleType === 'file' &&  exampleFiles.length > 0) {
            placement.exampleImagesBase64 = exampleFiles.map(el => el.base64);
            placement.exampleImagesName = exampleFiles.map(el => el.name)
        }

        setName('');
        setDescription('');
        setExampleUrl('');
        setExampleUrls([])
        setExampleFiles([])
        if (Object.keys(tags).length !== 0) {
            placement.meta = tags;
            setTags({});
        }

        setMainContent([
            ...mainContent,
            placement
        ]);
    }

    const uploadNewPlacement = () => {
        handleClose();
        const cleanedMainContent = [];
        mainContent.forEach(el => {
            const cleanEl = {...el};
            delete cleanEl.exampleImagesName;
            cleanedMainContent.push(cleanEl);
        })
        console.log(mainContent)
        addNewPlacements(cleanedMainContent);

    }

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span>Add Placement</span>
                <IconButton
                    onClick={_ => handleClose()}
                    sx={{ml: 'auto'}}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container={true} spacing={4}>
                    <Grid item xs={4}>
                        <Stack>
                            <TextField
                                label={`Placement Name*`}
                                variant="standard"
                                fullWidth
                                value={name}
                                onChange={
                                    e => {
                                        setName(e.target.value)
                                    }
                                }
                            />

                            <TextField
                                label={`Placement Description *`}
                                variant="standard"
                                multiline
                                rows={2}
                                value={description}
                                onChange={
                                    e => {
                                        setDescription(e.target.value)
                                    }
                                }
                                sx={{
                                    my: 4,
                                }}
                            />

                            <Grid
                                container
                            >
                                <Grid item={true} xs={6}
                                      sx={{
                                          fontSize: '1rem',
                                          fontWeight: 'bold',
                                      }}
                                >
                                   Example Images*
                                </Grid>
                                <Grid item={true} xs={6}>
                                    <Grid
                                        component="label"
                                        container
                                        alignItems="center"
                                        justifyContent={'end'}
                                        sx={{mb: 0}}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant={'body1'}
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    color: exampleType === 'Url' ? 'primary.main' : "text.disabled"
                                                }}
                                            >
                                                URLs
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Switch
                                                size={'small'}
                                                checked={exampleType !== 'Url'} // relevant state for your case
                                                onChange={handleChangeSwitch} // relevant method to handle your change
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant={'body1'}
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    color: exampleType !== 'Url' ? 'primary.main' : "text.disabled"
                                                }}
                                            >
                                                File
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                container={true}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                {
                                    exampleType !== 'file' ?
                                        (
                                            <>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Placement URL*"
                                                        placeholder={'E.g. https://coca-cola.com'}
                                                        variant="standard"
                                                        fullWidth
                                                        value={exampleUrl}
                                                        onChange={e => {
                                                            setExampleUrl(e.target.value);
                                                            }
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={1}
                                                      textAlign={'center'}>
                                                    <IconButton
                                                        color={'success'}
                                                        disabled={exampleUrl.length === 0}
                                                        onClick={_ => {
                                                            setExampleUrls(
                                                                [
                                                                    ...exampleUrls,
                                                                    exampleUrl
                                                                ]
                                                            );
                                                            setExampleUrl('');
                                                        }}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                >
                                                    <label
                                                        htmlFor="contained-button-file"
                                                    >
                                                        <input
                                                            style={{display: 'none'}}
                                                            accept="image/jpeg"
                                                            id="contained-button-file"
                                                            type="file"
                                                            ref={fileInput}
                                                            onChange={e => {
                                                                onExampleFileChange(e.target.files)
                                                                e.target.value = '';
                                                            }}
                                                        />
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            component="span"
                                                            startIcon={
                                                                <InsertPhotoIcon/>
                                                            }
                                                            disabled={loadingFile}

                                                        >
                                                            {(loadingFile)
                                                                ? <Skeleton variant={'text'} children={<span>Upload</span>}/>
                                                                : 'Upload'
                                                            }

                                                        </Button>
                                                    </label>

                                                </Grid>
                                            </>
                                        )
                                }
                            </Grid>
                            {

                                exampleType !== 'file'
                                    ? <AnimatedList
                                        items={exampleUrls}
                                        removeListItem={item => setExampleUrls((prev) => [...prev.filter((i) => i !== item)])}
                                    />
                                    : (
                                        <>
                                            <AnimatedDoubleList
                                                items={exampleFiles.map(el => el.name)}
                                                subtitles={exampleFiles.map(el => niceBytes(el.size))}
                                                removeListItem={item => setExampleFiles((prev) => [...prev.filter((i) => i.name !== item)])}
                                            />
                                        </>
                                    )
                            }

                            <CollapseTagCard
                                tags={tags}
                                setTags={setTags}
                            />
                            <Button
                                variant={'contained'}
                                fullWidth
                                onClick={_ => {
                                    handleAddToMainList()
                                }}
                                sx={{
                                    fontWeight: 'bold',
                                    my: 3,
                                }}
                                disabled={
                                    name.length === 0
                                    || description.length === 0
                                    || (exampleUrls.length === 0 && exampleFiles.length === 0)
                                }
                            >
                                Add To List >>
                            </Button>
                            <Box
                                sx={{
                                    fontSize: '10px',
                                }}>
                                <InfoIcon sx={{fontSize: '14px', color: '#8092A6'}}/> Placements will become active
                                within your contracted time period.
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={8}>
                        <AddPlacementsSummaryTable
                            rows={mainContent}
                            handleRemoveItem={item => setMainContent(mainContent.filter(el => el.name !== item.name))}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions
                sx={{
                    borderTop: '1px solid #ccc',
                    py: 2,
                    px: 3,
                }}
            >
                <Grid
                    container={true}
                    justifyContent={'end'}
                >
                    <Grid
                        item

                    >
                        <Button
                            variant={'contained'}
                            sx={{ml: 'auto'}}
                            disabled={mainContent.length === 0}
                            onClick={_ => {
                                uploadNewPlacement()
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}


export default AddPlacementModal;
